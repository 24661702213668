import { regex } from "@/shared/utils/constants";

/**
 * Add internet protocol automatically
 *
 * @param url
 * @returns
 */
export const addInternetProtocol = (url: string) => {
  if (regex.protocolHTTPS.test(url)) {
    // url already have HTTPS protocol, return as is
    return url;
  } else if (regex.protocolHTTP.test(url)) {
    // url already have HTTP protocol, return as HTTPS
    return url.replace("http", "https");
  }

  // add HTTPS protocol
  return `https://${url}`;
};

/**
 * Remove internet protocol automatically, opposite of addInternetProtocol
 * Used in showing fields with HTTPS prefixed URLs
 *
 * @param url
 * @returns
 */
export const removeInternetProtocol = (url: string) => {
  // remove https first then http if protocol is not https
  return url.replace("https://", "").replace("http://", "");
};
